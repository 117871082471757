import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './components/LandingPage';
import Body from './components/Body';
import SignupPage from './components/SignupPage';
import './App.css';
import Header from './components/Header';
import Footer from './components/Footer';
import FeatureSection from './components/FeatureSection';
import worker from './assets/worker_1.png';

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <Body />
      <Router>
        <Routes>
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
};

export default App;
