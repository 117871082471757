import React from 'react';
import { useNavigate } from 'react-router-dom'; // using react-router for navigation
import './LandingPage.css';

const LandingPage: React.FC = () => {
    const navigate = useNavigate();

    const navigateToSignup = () => {
        navigate('/signup'); // Navigate to the sign-up route
    };

    return (
        <div className="LandingPage">
            <a href="https://form.typeform.com/to/GaUMdoIy" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                <button>Sign Up</button>
            </a>
            {/* More Content */}
        </div>
    );
};

export default LandingPage;