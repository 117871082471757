import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SignupPage.css';

const SignupPage: React.FC = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        // You would send the data to your server here
        console.log('Subscribe:', { email, name });

        // TODO: Replace with actual API call to your backend
        // const response = await fetch('/api/subscribe', { method: 'POST', body: JSON.stringify({ email, name }) });
        // const result = await response.json();
        // console.log(result);

        setEmail('');
        setName('');
        goBack();
    };
    const goBack = () => {
        navigate(-1); // Go back to the previous page
    };
    return (
        <div className="signup-page">
            <form onSubmit={handleSubmit} className='signup-form'>
                <input
                    type="email"
                    placeholder="Email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <div></div>
                <button type="submit">←Sign Up</button>
            </form>
        </div>
    );
};

export default SignupPage;
