import React, { useState, useEffect } from 'react';
import FeatureSection from './FeatureSection'
import './Body.css';
import worker1 from '../assets/worker_1.png';
import worker2 from '../assets/worker_2.png';
import worker3 from '../assets/worker_3.png';
import robot1 from '../assets/robot_1.png';
import robot2 from '../assets/robot_2.png';
import robot3 from '../assets/robot_3.png';
// Sample data array
const featureSet1 = [
    {
        icon: <img src={worker1} />,
        title: 'Conversational Co-Pilot answers your toughest questions',
        description: 'Just ask a question and get instant, accurate insights. No more waiting on reports or wrangling spreadsheets. ',
    },
    {
        icon: <img src={worker2} />,
        title: 'Uncover hidden cost-out opportunities with your supplier base ',
        description: 'Your Co-Pilot surfaces helpful insights on negotiation levers so you can spend less time crunching numbers and more time on strategic initiatives.',
    },
    {
        icon: <img src={worker3} />,
        title: 'Self-Learning Knowledge Base',
        description: 'Leverage decades of collective purchasing experience. Co-Pilot  turns tribal knowledge into a scalable competitive advantage',
    },
];
const featureSet2 = [
    {
        icon: <img src={robot1} />,
        title: 'Predictive Inventory Planning',
        description: 'Say goodbye to excess inventory costs and stockouts.Your co-pilot uses AI to recommend optimal inventory levels tailored to your product lines and supply chain complexities.',
    },
    {
        icon: <img src={robot2} />,
        title: 'Supplier Performance Tracking',
        description: 'Get pro-active nudges as your co-pilot automatically monitors key KPIs like on-time delivery, quality, responsiveness, and identify risks or opportunities for supplier consolidation.',
    },
    {
        icon: <img src={robot3} />,
        title: 'Demand Forecasting',
        description: 'Self-learning models accurately project future demand by analyzing historical sales data, market trends, and demand factors.',
    },
];

const targetAudience = ["Procurement Managers", "Warehouse Managers", "Supply Chain Managers", "Commodity Leaders"]

const MainContent: React.FC = () => {
    const [audienceIndex, setAudienceIndex] = useState(0);

    useEffect(() => {
        // Set up a timer to change the audience every 5 seconds
        const timer = setInterval(() => {
            setAudienceIndex(prevIndex => (prevIndex + 1) % targetAudience.length);
        }, 2000);

        // Clean up the timer when the component unmounts
        return () => clearInterval(timer);
    }, []);

    return (
        <div>
            <main className="Body">
                <h1>The AI Co-Pilot for <span className="audience">{targetAudience[audienceIndex]}</span></h1>
                <p>
                    OptilyERP is an AI guide that optimizes, automates, and uncovers insights across your supply chain operations
                </p>
            </main>
            <div className='DemoButton'>
                <a href="https://form.typeform.com/to/GaUMdoIy" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                    <button>→ Join Waitlist</button>
                </a>
            </div>
            <div className='turbocharge'>
                <h1>All the tools you need to turbocharge your Commodity Manager </h1>
                <p>
                    Our AI-powered ERP Co-Pilot makes your ERP less rigid, identifying patterns, trends, and insights not obvious to supply chain managers.
                </p>
            </div>
            <FeatureSection features={featureSet1} />
            <FeatureSection features={featureSet2} />
            <div className='Closer'>
                <h1>Ready to transform your supply chain?</h1>
            </div>

        </div>
    );
};

export default MainContent;
