import React from 'react';
import FeatureCard from './FeatureCard';
import './FeatureSection.css';

type Feature = {
    icon: React.ReactNode; // Accept a ReactNode for the image
    title: string;
    description: string;
};

type FeatureSectionProps = {
    features: Feature[]; // Accept an array of features
};

const FeatureSection: React.FC<FeatureSectionProps> = ({ features }) => (
    <div className="feature-section">
        {features.map((feature, index) => (
            <FeatureCard key={index} {...feature} />
        ))}
    </div>
);

export default FeatureSection;