import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="App-footer">
            <p>
                © {currentYear} OptilyERP
            </p>
        </footer>
    );
};

export default Footer;
