import React from 'react';
import './Header.css';
import logo from '../assets/logo.png';

const Header: React.FC = () => {
    return (
        <header className="App-header">
            <div className="header-content">
                <img src={logo}></img>
                <div className="logo">OptilyERP</div>
            </div>
        </header>
    );
};

export default Header;
